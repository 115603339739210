import { EmailAvailableDto } from '../../api/gen';

export class EmailAvailable {
  available?: boolean;
  eMail?: string | null;

  static fromDto(dto: EmailAvailableDto): EmailAvailable {
    return { available: dto.available, eMail: dto.eMail } as EmailAvailable;
  }
}
