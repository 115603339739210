/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SubscriptionType = 'Trial' | 'Starter' | 'Pro' | 'Ultimate' | 'Individual';

export const SubscriptionType = {
    Trial: 'Trial' as SubscriptionType,
    Starter: 'Starter' as SubscriptionType,
    Pro: 'Pro' as SubscriptionType,
    Ultimate: 'Ultimate' as SubscriptionType,
    Individual: 'Individual' as SubscriptionType
};

