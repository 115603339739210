import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PermissionInformationDialogComponent } from '../dialogs/permission-information-dialog/permission-information-dialog.component';
import { PermissionService } from '../services/permission.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-call-permission-information',
  templateUrl: './call-permission-information.component.html',
  styleUrls: ['./call-permission-information.component.scss'],
})
export class CallPermissionInformationComponent {
  protected permissionErrorTextSubject$: BehaviorSubject<string>;
  protected permissionErrorDescriptionSubject$: BehaviorSubject<string>;
  protected shouldShouldPermissionNotification$: BehaviorSubject<boolean>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly permissionService: PermissionService,
  ) {
    this.permissionErrorTextSubject$ = new BehaviorSubject<string>('***');
    this.permissionErrorDescriptionSubject$ = new BehaviorSubject<string>(
      '***',
    );
    this.shouldShouldPermissionNotification$ = new BehaviorSubject<boolean>(
      false,
    );

    this.permissionService.observeChanges().subscribe((value) => {
      if (value.isCamGiven() && value.isMicGiven()) {
        this.permissionErrorTextSubject$.next($localize`Kamera & Mikrofon`);
        this.permissionErrorDescriptionSubject$.next($localize`Video und Ton`);
      } else if (value.isCamGiven()) {
        this.permissionErrorTextSubject$.next($localize`Kamera`);
        this.permissionErrorDescriptionSubject$.next($localize`Video`);
      } else if (value.isMicGiven()) {
        this.permissionErrorTextSubject$.next($localize`Mikrofon`);
        this.permissionErrorDescriptionSubject$.next($localize`Ton`);
      }

      if (value.isCamGiven() || value.isMicGiven()) {
        this.shouldShouldPermissionNotification$.next(true);
      } else {
        this.shouldShouldPermissionNotification$.next(false);
      }
    });
  }

  protected openPermissionInformationDialog() {
    this.dialog.open(PermissionInformationDialogComponent, {
      width: '40rem',
    });
  }
}
