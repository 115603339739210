import { LocalDataTrack } from 'twilio-video';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ZoomState } from '../call-ctrl.service';

@Injectable({
  providedIn: 'root',
})
export class DataTrackService {
  private dataTrack?: LocalDataTrack;
  public dataTrackUpdated$ = new Subject<LocalDataTrack>();

  public sendDataObject(data: DataTrackObject): void {
    this.dataTrack?.send(JSON.stringify(data));
  }

  public clearTracks(): void {
    this.dataTrack = null;
    this.dataTrackUpdated$.next(null);
  }

  private createDataTrack(): LocalDataTrack {
    this.dataTrack = new LocalDataTrack();
    this.dataTrackUpdated$.next(this.dataTrack);
    return this.dataTrack;
  }

  getDataTrack(): LocalDataTrack | undefined {
    if (this.dataTrack) return this.dataTrack;
    this.dataTrack = this.createDataTrack();
    return this.dataTrack;
  }

  public takeScreenshot(): void {
    this.sendDataObject(
      DataTrackObject.withType(DataTrackObjectType.SCREENSHOT),
    );
  }

  public takeLivePhoto(): void {
    this.sendDataObject(
      DataTrackObject.withType(DataTrackObjectType.LIVE_IMAGE),
    );
  }

  public toggleFlash(): void {
    this.sendDataObject(
      DataTrackObject.withType(DataTrackObjectType.TOGGLE_FLASH).withData(null),
    );
  }

  public changeZoom(newState: ZoomState): void {
    this.sendDataObject(
      DataTrackObject.withType(DataTrackObjectType.CHANGE_ZOOM).withData(
        newState,
      ),
    );
  }

  public requestLocation() {
    this.sendDataObject(
      DataTrackObject.withType(DataTrackObjectType.LOCATION_REQUEST),
    );
  }
}

export class DataTrackObject {
  type: DataTrackObjectType;
  data?: any;

  private constructor(type: DataTrackObjectType) {
    this.type = type;
  }

  public static withType(type: DataTrackObjectType): DataTrackObject {
    return new DataTrackObject(type);
  }

  public withData(data?: any): DataTrackObject {
    this.data = data;
    return this;
  }
}

export enum DataTrackObjectType {
  PRESENTING = 0,
  SCREENSHOT = 1,
  ANNOTATE = 2,
  CAPABILITIES_ANSWER = 3,
  TOGGLE_FLASH = 4,
  DIAGNOSTICS = 5,
  HEARTBEAT = 6,
  CHANGE_ZOOM = 7,
  LIVE_IMAGE = 8,
  LOCATION_REQUEST = 9,
  LOCATION_RESPONSE = 10,
}
