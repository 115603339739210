import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  DrawEvent,
  Mode,
} from '../../../annotations/drawing-canvas/drawing-canvas.component';
import { VideoChatService } from '../../services/videochat.service';
import { CallCtrlService } from '../../services/call-ctrl.service';
import { CanvasSyncService } from '../../../annotations/services/canvas-sync.service';
import { filter, first, map, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CapabilitiesService } from '../../services/capabilities.service';
import { EndCallDialogComponent } from '../../dialogs/end-call-dialog/end-call-dialog.component';
import { PictureOverlayComponent } from '../../picture-overlay/picture-overlay.component';
import { MatDialog } from '@angular/material/dialog';
import { SlideInDirection } from '../../new-attachment-preview/new-attachment-preview.component';
import {
  AppointmentAttachmentsService,
  CallAttachment,
} from '../../services/appointment-attachments.service';
import {
  ContentType,
  VideochatContentService,
} from '../../services/videochat-content.service';
import { AllowedControls } from '../../shared/content/present-image/present-image.component';
import { Participant } from 'twilio-video/tsdef/Participant';
import { RemoteParticipant } from 'twilio-video';
import { DiagnosticsService } from '../../services/diagnostics/diagnostics.service';
import { DataTrackService } from '../../services/track-ctrl/data-track.service';
import {
  AppointmentMessageType,
  AppointmentMessagingService,
} from '../../../services/signaling/messaging/appointment-messaging.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-portrait-appointment',
  templateUrl: './portrait-appointment.component.html',
  styleUrls: ['./portrait-appointment.component.scss'],
})
export class PortraitAppointmentComponent implements AfterViewInit, OnDestroy {
  @ViewChild('sizeWrapper') sizeWrapper: ElementRef;
  @ViewChild('screenshotOverlay') screenshotOverlay: PictureOverlayComponent;

  protected readonly ContentType = ContentType;

  private readonly unsubscribe$ = new Subject<void>();
  protected currentImage: CallAttachment;

  public showMovingHand = false;

  protected isPictureInProgress = false;

  slideInDirection = SlideInDirection.Y;
  protected allowedImageCtrls: AllowedControls = {
    ocr: false,
    edit: false,
    present: true,
  };

  protected remoteVideoTrackEnabled$: Observable<boolean>;

  constructor(
    public readonly dataTrackCtrl: DataTrackService,
    public readonly videoChatService: VideoChatService,
    private readonly dialog: MatDialog,
    protected readonly callCtrlService: CallCtrlService,
    protected readonly canvasSyncService: CanvasSyncService,
    private readonly capabilitiesService: CapabilitiesService,
    private readonly attachmentService: AppointmentAttachmentsService,
    private readonly appointmentMessagingService: AppointmentMessagingService,
    protected readonly videochatContentService: VideochatContentService,
    private readonly cdr: ChangeDetectorRef,
    private readonly diagnostics: DiagnosticsService,
    private readonly snackBar: MatSnackBar,
  ) {
    this.remoteVideoTrackEnabled$ = this.diagnostics.diagnosticsChanged$.pipe(
      map((x) => x.remoteCameraTurnedOn),
    );
    this.attachmentService
      .onCurrentAttachment()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        this.currentImage = x?.attachments[x?.currentIndex];
      });

    this.appointmentMessagingService
      .onMessage(AppointmentMessageType.CancelCreateScreenshot)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.isPictureInProgress = false;
        this.snackBar.open(
          $localize`Kunde hat Foto nicht freigegeben!`,
          $localize`Ok`,
          { duration: 3000 },
        );
      });

    this.videoChatService.activeRoom$
      .pipe(filter((x) => !!x))
      .subscribe((activeRoom) => {
        if (activeRoom.room) {
          this.participantsChanged(activeRoom.room.participants);
          activeRoom.room
            .on('participantConnected', () =>
              this.participantsChanged(activeRoom.room.participants),
            )
            .on('participantDisconnected', () =>
              this.participantsChanged(activeRoom.room.participants),
            )
            .on('participantReconnected', () =>
              this.participantsChanged(activeRoom.room.participants),
            );
        }
      });

    this.callCtrlService.modeChannel$.next(Mode.Point);
    this.capabilitiesService.capabilitiesChanged$
      .pipe(
        takeUntil(this.unsubscribe$),
        map((x) => {
          return { width: x.cameraWidth, height: x.cameraHeight, zoom: x.zoom };
        }),
      )
      .subscribe((x) => {
        if (this.sizeWrapper) {
          this.sizeWrapper.nativeElement.style.aspectRatio =
            x.width + '/' + x.height;

          // TODO: we might be able to avoid setting the style of both elements
          if (x.height > x.width) {
            this.sizeWrapper.nativeElement.style.height = '100%';
            this.sizeWrapper.nativeElement.style.width = 'auto';
          } else {
            this.sizeWrapper.nativeElement.style.height = 'auto';
            this.sizeWrapper.nativeElement.style.width = '100%';
          }
        }
      });
  }

  public drawOnVideo(data: DrawEvent) {
    this.videoChatService.drawOnVideo(data);
  }

  ngAfterViewInit(): void {
    this.videoChatService.activeRoom$
      .pipe(
        filter((x) => !!x?.room),
        first(),
      )
      .subscribe(() => {
        this.canvasSyncService.referenceElementChanged(
          this.sizeWrapper.nativeElement,
        );
      });
  }

  protected endCall() {
    const dialogRef = this.dialog.open(EndCallDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.videoChatService.endCall();
      }
    });
  }

  public async takeScreenshot() {
    this.isPictureInProgress = true;
    this.attachmentService.newAttachment$.pipe(first()).subscribe(() => {
      this.isPictureInProgress = false;
    });
    this.dataTrackCtrl.takeScreenshot();
    this.screenshotOverlay.takeScreenshot();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public changeMovingHand(isAvailable: boolean) {
    if (isAvailable && !this.showMovingHand) {
      this.showMovingHand = true;
    }
  }

  protected closeAttachment() {
    this.attachmentService.changeCurrentAttachment(null);
  }

  // NOT that beautiful way of bringing the invitation dialog to the front and capture input
  private participantsChanged(
    participants: Map<Participant.SID, RemoteParticipant>,
  ) {
    if (participants.size > 0) {
      document.getElementById('participants').style.zIndex = '0';
      document.getElementById('picture-overlay').style.zIndex = '1';
    } else if (participants.size === 0) {
      document.getElementById('participants').style.zIndex = '2';
      document.getElementById('picture-overlay').style.zIndex = '1';
      // Fix to show join-link again
      this.cdr.markForCheck();
    }
  }
}
