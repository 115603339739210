/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Origin } from './origin';
import { Author } from './author';
import { AssociatedCallPeriod } from './associatedCallPeriod';


export interface CreateAttachment { 
    storageIdentifier?: string;
    blobPath?: string | null;
    period?: AssociatedCallPeriod;
    inquiryIdentifier?: string;
    fileName?: string | null;
    extension?: string | null;
    note?: string | null;
    shared?: boolean;
    origin?: Origin;
    author?: Author;
    createThumbnail?: boolean;
}

