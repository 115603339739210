/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LineTypeIntelligenceDto } from './lineTypeIntelligenceDto';
import { PhoneNumberValidationErrorDto } from './phoneNumberValidationErrorDto';


export interface PhoneNumberValidationDto { 
    callingCountryCode?: string | null;
    countryCode?: string | null;
    phoneNumber?: string | null;
    nationalFormat?: string | null;
    valid?: boolean | null;
    validationErrors?: Array<PhoneNumberValidationErrorDto> | null;
    lineTypeIntelligence?: LineTypeIntelligenceDto;
}

