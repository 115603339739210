/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportOutputFormat } from './reportOutputFormat';
import { ReportType } from './reportType';


export interface InquirySummaryReportConfiguration { 
    includeHeader?: boolean;
    includeCompanySettings?: boolean;
    includeInquiryInformation?: boolean;
    includeTimeline?: boolean;
    includeNotes?: boolean;
    includeAttachments?: boolean;
    attachmentIdentifiersToInclude?: Array<string> | null;
    templateIdentifier?: string | null;
    type?: ReportType;
    outputFormat?: ReportOutputFormat;
}

