<div
  *ngIf="shouldShouldPermissionNotification$ | async"
  class="call-permission-information"
>
  <div class="permission-information-text">
    <span class="permission-text-title" i18n
      >{{ permissionErrorTextSubject$ | async }} Berechtigung nicht
      gesetzt!</span
    >
    <br />
    <span class="permission-text-description" i18n
      >Diese muss aktiviert werden, um
      {{ permissionErrorDescriptionSubject$ | async }} übertragen zu
      können!</span
    >
    <br />
    <span class="permission-text-description" i18n
      >Nach Änderung der Einstellungen <b>Seite bitte neu laden!</b></span
    >
  </div>
  <div (click)="openPermissionInformationDialog()" class="permission-more-info">
    <mat-icon>more_vert</mat-icon>
  </div>
</div>
