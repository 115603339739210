import { Employee } from '../../model/employee/employee';

export class EmployeeOverviewData {
  value: string;
  displayName: string;
  email: string;

  static fromEmployee(employee: Employee): EmployeeOverviewData {
    return {
      value: employee.externalIdentifier,
      displayName: `${employee.firstName} ${employee.lastName}`,
      email: employee.email,
    } as EmployeeOverviewData;
  }
}
