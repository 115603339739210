import { Component } from '@angular/core';

@Component({
  selector: 'app-permission-information-dialog',
  templateUrl: './permission-information-dialog.component.html',
  styleUrls: ['./permission-information-dialog.component.scss'],
})
export class PermissionInformationDialogComponent {
  constructor() {}
}
