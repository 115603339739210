/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VideoAppointmentAccessDto { 
    inquiryIdentifier?: string;
    assignedAgent?: string | null;
    roomName?: string | null;
    roomScheduledFor?: string;
    roomFinishedAt?: string | null;
    accessToken?: string | null;
    recordingAllowed?: boolean;
    livePhotoAllowed?: boolean;
    customersCanUploadFilesDuringCall?: boolean;
    liveChatTranslationsEnabled?: boolean;
    agentFirstJoin?: string | null;
    customerFirstJoin?: string | null;
    agentFirstName?: string | null;
    agentLastName?: string | null;
}

