import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-item-column-display',
  templateUrl: './item-column-display.component.html',
  styleUrls: ['./item-column-display.component.scss'],
})
export class ItemColumnDisplayComponent {
  @Input() label: string;
  @Input() value: string;
}
