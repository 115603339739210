/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InvitationDto } from './invitationDto';
import { Gender } from './gender';


export interface CreateNewScheduledInquiry { 
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    street?: string | null;
    houseNumber?: string | null;
    zipCode?: string | null;
    city?: string | null;
    scheduledFor?: string | null;
    startInstant?: boolean;
    gender?: Gender;
    assignedAgent?: string;
    invitation?: InvitationDto;
}

