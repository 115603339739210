export const environment = {
  production: false,
  environment: 'testing',
  apiPath:
    'https://blankmile-api-testing.kindplant-4a458331.northeurope.azurecontainerapps.io',
  storagePath: 'https://blankmiletesting.blob.core.windows.net',
  editorApiKey: '7ufdxusejinvqfq5rcsiru3buke0r3m6pq7k43vcsxr9pv5x',
  participantTimeout: 6000,
  stripePricingTableId: 'prctbl_1NQkk3LRVwUsaICQ8YU4MNXU',
  stripePublishableKey:
    'pk_live_51LkjLJLRVwUsaICQmI9NAUdg1hdaeBhMNYhgRCXcQbYl92m86V3iLfTsi3SeWBLDkCJYlwFLuSlDw1M4kcREk88m002TsEdT4h',
  auth: {
    authority: 'https://auth.testing.blankmile.com',
    clientId: 'aidar-helper-client',
    clientRootTemplate: 'https://*.testing.blankmile.com',
    clientScope: 'openid profile email',
    apiRoot:
      'https://blankmile-api-testing.kindplant-4a458331.northeurope.azurecontainerapps.io',
  },
  useSentry: true,
  sentryDsn:
    'https://10f18aff40935407daa126094e8ce24d@o4508250093387776.ingest.de.sentry.io/4508256022429776',
  video: {
    logLevel: 'error',
  },
};
