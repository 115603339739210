<div>
  <div *ngIf="loading" class="loading-shade">
    <mat-spinner></mat-spinner>
  </div>
  <div>
    <table [dataSource]="dataSource" class="mat-elevation-z2" mat-table matSort>
      <ng-container matColumnDef="state">
        <th *matHeaderCellDef mat-header-cell>Status</th>
        <td *matCellDef="let element" mat-cell>
          <app-status-tag
            [status]="element.state"
            [autoDeletionDate]="element.autoDeletionScheduledAt"
          ></app-status-tag>
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>Name</th>
        <td *matCellDef="let element" mat-cell>
          <span *ngIf="element.firstName || element.lastName"
            >{{ element.firstName }} {{ element.lastName }}</span
          >

          <span
            *ngIf="!element.firstName && !element.lastName"
            class="no-name-set"
            i18n
            >Kein Name angegeben</span
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>Email</th>
        <td *matCellDef="let element" mat-cell>{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>Telefon</th>
        <td *matCellDef="let element" mat-cell>{{ element.phoneNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="inquiryCreatedAt">
        <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>
          Anfragedatum
        </th>
        <td *matCellDef="let element" mat-cell>
          <app-date-range
            [startDate]="element.inquiryCreatedAt"
          ></app-date-range>
        </td>
      </ng-container>

      <ng-container matColumnDef="scheduledFor">
        <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>Termin</th>
        <!-- <td mat-cell *matCellDef="let element">{{element.scheduledFor | date: 'EEEEEE, dd.MM.yyyy HH:mm'}}</td> -->
        <td *matCellDef="let element" mat-cell>
          <app-appointment-date
            [appointmentConfirmed]="element.appointmentConfirmed"
            [appointmentDeclined]="element.appointmentDeclined"
            [endDate]="element.endedAt"
            [inquiryState]="element.state"
            [startDate]="element.scheduledFor"
          ></app-appointment-date>
        </td>
      </ng-container>

      <ng-container matColumnDef="assignedEmployeeDisplayName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>
          Mitarbeiter
        </th>
        <td *matCellDef="let element" mat-cell>
          {{ element.assignedEmployeeDisplayName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>Dauer</th>
        <td *matCellDef="let element" mat-cell>{{ element.duration }}</td>
      </ng-container>

      <ng-container matColumnDef="attachmentCount">
        <th *matHeaderCellDef mat-header-cell i18n>Dateien</th>
        <td *matCellDef="let element" mat-cell>
          {{ element.attachmentCount ?? "-" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="details">
        <th *matHeaderCellDef mat-header-cell i18n>Details</th>
        <td *matCellDef="let element" mat-cell>
          <button
            (click)="onInquirySelected(element)"
            color="primary"
            mat-icon-button
          >
            <mat-icon class="table-details-icon">arrow_forward_ios</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="inquiryActive">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell>
          <div
            [ngClass]="{ blinking: isActiveCall(element) }"
            class="dashboard-entry-blinking"
          ></div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>

      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

      <tr *matNoDataRow class="mat-row">
        <td
          [attr.colspan]="displayedColumns.length"
          class="mat-cell no-data-row ns"
          i18n
        >
          Noch keine Anrufe.
        </td>
      </tr>
    </table>
  </div>
</div>
