/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BillingAddressDto } from './billingAddressDto';


export interface BillingInformationDto { 
    name?: string | null;
    eMail?: string | null;
    phoneNumber?: string | null;
    sendTextNotificationIfPaymentFails?: boolean;
    address?: BillingAddressDto;
}

