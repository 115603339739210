/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InquiryAccessType } from './inquiryAccessType';
import { InquiryAccessError } from './inquiryAccessError';


export interface InquiryAccessDto { 
    accessType?: InquiryAccessType;
    canAccess?: boolean;
    error?: InquiryAccessError;
}

