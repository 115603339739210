<h2 mat-dialog-title i18n>Wie können Sie Berechtigungen aktivieren?</h2>
<mat-dialog-content i18n>
  Die Berechtigungen zur Verwendung der Kamera und des Mikrofons können, je
  nachdem welchen Browser Sie verwenden, unterschiedlich zu aktivieren sein.
  <br /><br />
  Mehr Informationenfür Ihren jeweiligen Browser finden Sie unter folgenden
  Links: <br />
  <table>
    <tbody>
      <tr>
        <td>
          <a
            href="https://support.google.com/chrome/answer/2693767"
            target="_blank"
            >Google Chrome</a
          >
        </td>
      </tr>
      <tr>
        <td>
          <a
            href="https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions"
            target="_blank"
            >Mozilla Firefox</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a
            href="https://support.apple.com/en-gb/guide/safari/ibrwe2159f50/mac"
            target="_blank"
            >Apple Safari</a
          >
        </td>
      </tr>
      <tr>
        <td>
          <a
            href="https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857"
            target="_blank"
            >Microsoft Edge</a
          >
        </td>
      </tr>
    </tbody>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close i18n>Schließen</button>
</mat-dialog-actions>
