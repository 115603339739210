<h2 mat-dialog-title i18n>Terminvorschlag</h2>
<mat-dialog-content class="dialog-content">
  <div class="selection">
    <div class="left-section">
      <h3 i18n>Datum</h3>
      <div>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label i18n>Datum auswählen</mat-label>
          <input
            (dateChange)="dateChanged($event)"
            [(ngModel)]="selectedDate"
            [matDatepicker]="picker"
            [min]="minDate"
            matInput
          />
          <mat-datepicker-toggle
            [for]="picker"
            matSuffix
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="right-section">
      <h3 i18n>Zeit auswählen</h3>
      <div class="time-slots">
        <div
          (click)="selectSlot(slot)"
          *ngFor="let slot of availableSlots"
          [ngClass]="{ 'slot-selected': slot === selectedSlot }"
          class="slot"
        >
          {{ slot | date: "HH:mm" }}
        </div>
      </div>
    </div>
  </div>
  <div>
    <h3 i18n>Termin:</h3>
    <span class="selectedSlot">{{
      selectedSlot | date: "EEEEEE, dd.MM.yyyy HH:mm"
    }}</span>
    <span *ngIf="!selectedSlot" i18n>Kein Termin ausgewählt</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button [disabled]="loading" mat-button mat-dialog-close>Abbrechen</button>
  <button
    (click)="submit()"
    [disabled]="!selectedSlot || loading"
    mat-button
    i18n
  >
    Termin vorschlagen
  </button>
</mat-dialog-actions>
