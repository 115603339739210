<div class="header">
  <div class="search-bar">
    <mat-form-field>
      <mat-label i18n>Filter</mat-label>
      <input
        #input
        (keyup)="applyFilter($event)"
        matInput
        i18n-placeholder
        placeholder="Name, E-Mail oder Telefonnummer"
      />
    </mat-form-field>
  </div>
  <div class="filter" *ngIf="isManagerOrAdmin$ | async">
    <mat-form-field>
      <mat-label i18n>Mitarbeiter</mat-label>
      <mat-select
        (selectionChange)="employeeFilterChanged()"
        [formControl]="employees"
        multiple
      >
        <mat-option
          *ngFor="let employee of employeeList"
          [value]="employee.value"
          >{{ employee.displayName }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="filter">
    <mat-form-field>
      <mat-label i18n>Anfragedatum</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input
          formControlName="start"
          matStartDate
          i18n-placeholder
          placeholder="Von"
        />
        <input
          (dateChange)="refresh()"
          formControlName="end"
          matEndDate
          i18n-placeholder
          placeholder="Bis"
        />
      </mat-date-range-input>
      <!-- <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint> -->
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
</div>

<div>
  @if (employeeLoading || inquiriesLoading) {
    <div class="loading-shade">
      <mat-spinner></mat-spinner>
    </div>
  } @else {
    <div>
      <table
        [dataSource]="dataSource"
        class="mat-elevation-z2"
        mat-table
        matSort
      >
        <ng-container matColumnDef="state">
          <th *matHeaderCellDef mat-header-cell>
            <span class="header-status-long-text" i18n>Status</span>
            <span class="header-status-short-text"></span>
          </th>
          <td *matCellDef="let element" mat-cell>
            <app-status-tag
              [status]="element.state"
              [autoDeletionDate]="element.autoDeletionScheduledAt"
            >
            </app-status-tag>
          </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>Name</th>
          <td *matCellDef="let element" mat-cell>
            <span *ngIf="element.firstName || element.lastName"
              >{{ element.firstName }} {{ element.lastName }}</span
            >

            <span
              *ngIf="!element.firstName && !element.lastName"
              class="no-name-set"
              i18n
              >Kein Name angegeben</span
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>Email</th>
          <td *matCellDef="let element" mat-cell>{{ element.email }}</td>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
          <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>
            Telefon
          </th>
          <td *matCellDef="let element" mat-cell>{{ element.phoneNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="scheduledFor">
          <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>Termin</th>
          <!-- <td mat-cell *matCellDef="let element">{{element.scheduledFor | date: 'EEEEEE, dd.MM.yyyy HH:mm'}}</td> -->
          <td *matCellDef="let element" mat-cell>
            <app-appointment-date
              [appointmentConfirmed]="element.appointmentConfirmed"
              [appointmentDeclined]="element.appointmentDeclined"
              [endDate]="element.endedAt"
              [inquiryState]="element.state"
              [startDate]="element.scheduledFor"
            ></app-appointment-date>
          </td>
        </ng-container>

        <ng-container matColumnDef="assignedEmployeeDisplayName">
          <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>
            Mitarbeiter
          </th>
          <td *matCellDef="let element" mat-cell>
            <button
              [disabled]="!canUpdateAssignedEmployee(element)"
              [matMenuTriggerData]="{ inquirySummary: element }"
              [matMenuTriggerFor]="actionMenu"
              mat-button
              color="primary"
              (click)="employeeDisplayNameClicked($event)"
            >
              {{ element.assignedEmployeeDisplayName }}
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="duration">
          <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>Dauer</th>
          <td *matCellDef="let element" mat-cell>{{ element.duration }}</td>
        </ng-container>

        <ng-container matColumnDef="attachmentCount">
          <th *matHeaderCellDef mat-header-cell i18n>Dateien</th>
          <td *matCellDef="let element" mat-cell>
            {{ element.attachmentCount ?? "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="inquiryActive">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let element" mat-cell>
            <div
              [ngClass]="{ blinking: isActiveCall(element) }"
              class="dashboard-entry-blinking"
            ></div>
          </td>
        </ng-container>

        <mat-menu #actionMenu="matMenu">
          <ng-template let-inquirySummary="inquirySummary" matMenuContent>
            @for (
              employee of getAssignableEmployees(inquirySummary);
              track employee.value
            ) {
              <button
                (click)="onEmployeeAssigned(inquirySummary, employee)"
                mat-menu-item
                [matTooltip]="employee.email"
                matTooltipPosition="after"
              >
                <span>{{ employee.displayName }}</span>
              </button>
            }

            <button
              (click)="onEmployeeUnassigned(inquirySummary)"
              *ngIf="!!inquirySummary.assignedEmployeeIdentifier"
              mat-menu-item
            >
              <mat-icon style="color: darkred">delete</mat-icon>
              <span i18n>Zuweisung löschen</span>
            </button>
          </ng-template>
        </mat-menu>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>

        <tr
          class="call-history-row"
          *matRowDef="let row; columns: displayedColumns"
          mat-row
          (click)="onInquirySelected(row)"
        ></tr>

        <tr *matNoDataRow class="mat-row">
          <td
            [attr.colspan]="displayedColumns.length"
            class="mat-cell no-data-row"
            i18n
          >
            Keine Daten für die gewählten Filter oder noch keine Anfragen.
          </td>
        </tr>
      </table>
    </div>
  }
</div>
