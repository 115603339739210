/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentDto } from './attachmentDto';
import { EmployeeDto } from './employeeDto';
import { InquiryState } from './inquiryState';


export interface InquiryDashboardSummaryDto { 
    inquiryIdentifier?: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    street?: string | null;
    houseNumber?: string | null;
    zipCode?: string | null;
    city?: string | null;
    startInstant?: boolean;
    notes?: string | null;
    state?: InquiryState;
    employee?: EmployeeDto;
    inquiryCreatedAt?: string;
    appointmentScheduledFor?: string | null;
    appointmentEndedAt?: string | null;
    scheduledForConfirmed?: boolean;
    scheduledForDeclined?: boolean;
    autoDeletionScheduledAt?: string | null;
    autoDeletedAt?: string | null;
    manuallyDeletedAt?: string | null;
    manuallyDeletedBy?: string | null;
    attachments?: Array<AttachmentDto> | null;
}

