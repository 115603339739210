import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalTrackCtrlService } from '../../services/local-track-ctrl.service';

@Component({
  selector: 'app-audio-visualization',
  templateUrl: './audio-visualization.component.html',
  styleUrls: ['./audio-visualization.component.scss'],
})
export class AudioVisualizationComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();

  private readonly audioCtx: AudioContext = new window.AudioContext();

  constructor(private readonly trackCtrl: LocalTrackCtrlService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async ngOnInit(): Promise<void> {
    this.audioCtx.createAnalyser();
    await this.initializeDevice();
    this.trackCtrl.videoTrackUpdated$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (x) => {
        await this.initializeDevice();
      });
  }

  private async initializeDevice(): Promise<void> {
    try {
      // https://github.com/mdn/voice-change-o-matic/blob/gh-pages/scripts/app.js
      const analyzer = this.audioCtx.createAnalyser();
      analyzer.minDecibels = -90;
      analyzer.maxDecibels = -10;
      analyzer.smoothingTimeConstant = 0.85;

      const distortion = this.audioCtx.createWaveShaper();
      const gainNode = this.audioCtx.createGain();
      const biquadFilter = this.audioCtx.createBiquadFilter();
      const convolver = this.audioCtx.createConvolver();

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const source = this.audioCtx.createMediaStreamSource(stream);

      source.connect(distortion);
      distortion.connect(biquadFilter);
      biquadFilter.connect(gainNode);
      convolver.connect(gainNode);
      gainNode.connect(analyzer);
      analyzer.connect(this.audioCtx.destination);
    } catch (error) {
      if (error instanceof DOMException && error.name === 'NotAllowedError') {
        //Permission for Video / Audio is not granted
      }
    }
  }
}
