<div class="container">
  <div class="header">
    <span
      >{{ attachments[currentIndex].name }}.{{
        attachments[currentIndex].extension
      }}</span
    >
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="content-container">
    <mat-drawer-container>
      <mat-drawer
        #drawer
        class="example-sidenav"
        mode="side"
        opened
        position="end"
      >
      </mat-drawer>
      <div class="wrapper">
        <div class="drawer-content">
          <app-annotate-image
            [srcImage]="attachments[currentIndex]"
          ></app-annotate-image>
        </div>
      </div>
    </mat-drawer-container>
  </div>
</div>
