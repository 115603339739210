/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Department } from './department';
import { EmployeeType } from './employeeType';


export interface EmployeeDto { 
    externalIdentifier?: string;
    firstName?: string | null;
    lastName?: string | null;
    eMail?: string | null;
    eMailVerified?: boolean;
    phoneNumber?: string | null;
    enabled?: boolean;
    username?: string | null;
    avatarPath?: string | null;
    avatarThumbnailPath?: string | null;
    type?: EmployeeType;
    canReadDataOfOthers?: boolean;
    department?: Department;
    legalEntityId?: string | null;
    tenantId?: string | null;
}

