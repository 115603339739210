/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionType } from './subscriptionType';
import { PaymentProvider } from './paymentProvider';
import { ReferrerDto } from './referrerDto';
import { PaymentInterval } from './paymentInterval';
import { FeatureConfigurationDto } from './featureConfigurationDto';
import { BillingInformationDto } from './billingInformationDto';


export interface SubscriptionDto { 
    id?: string;
    externalIdentifier?: string | null;
    paymentProvider?: PaymentProvider;
    paymentInterval?: PaymentInterval;
    subscriptionType?: SubscriptionType;
    startedAt?: string | null;
    endedAt?: string | null;
    cancelledAt?: string | null;
    featureConfiguration?: FeatureConfigurationDto;
    billingInformation?: BillingInformationDto;
    referrer?: ReferrerDto;
}

