/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InquiryAccessError = 'None' | 'HelperOnly' | 'WrongAccessToken' | 'AccessTokenEnteredTooOften' | 'Unknown';

export const InquiryAccessError = {
    None: 'None' as InquiryAccessError,
    HelperOnly: 'HelperOnly' as InquiryAccessError,
    WrongAccessToken: 'WrongAccessToken' as InquiryAccessError,
    AccessTokenEnteredTooOften: 'AccessTokenEnteredTooOften' as InquiryAccessError,
    Unknown: 'Unknown' as InquiryAccessError
};

