/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InvitationMethod = 'Sms' | 'Email' | 'SmsAndEmail' | 'None';

export const InvitationMethod = {
    Sms: 'Sms' as InvitationMethod,
    Email: 'Email' as InvitationMethod,
    SmsAndEmail: 'SmsAndEmail' as InvitationMethod,
    None: 'None' as InvitationMethod
};

